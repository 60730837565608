import DashboardReport from './components/DashboardReport';
import ReportControlCard from './components/ReportControlCard';

const DashboardPage = () => (
  <div className='card mb-7'>
    <div className='card-body'>
      <DashboardReport />
    </div>
  </div>
)

const DashboardWrapper = () => {
  document.title = "Tổng quan"

  return (
    <div className='row'>
      <div className='col-sm-12'>
        <DashboardPage />
      </div>

      <div className='col-sm-12 mb-5'>
        <ReportControlCard />
      </div>

      {/* <div className='col-sm-6'>
        <StudentPage />
      </div>

      <div className='col-sm-6'>
        <ClassRoomListPage />
      </div> */}
    </div>
  )
}

export { DashboardWrapper }
