import axios from 'axios'
import {AuthModel, CurrentUserModel, UpdateUserInfo} from './_models'
import { BaseResponse } from '../../../common/models/_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/auth/verify-token`
export const LOGIN_URL = `${API_URL}/api/auth/login`
export const REFRESH_TOKEN = `${API_URL}/api/auth/refresh-token/`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const AUTH_URL = `${API_URL}/api/auth`

// Server should return AuthModel
export function login(userName: string, password: string) {
  return axios.post<BaseResponse<AuthModel>>(LOGIN_URL, {
    userName,
    password,
  })
}

export function refreshToken(refreshToken: string) {
  return axios.post<BaseResponse<AuthModel>>(REFRESH_TOKEN + refreshToken)
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken() {
  return axios.get<BaseResponse<CurrentUserModel>>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function updateUserInfo(request: UpdateUserInfo) {
  return axios.put<BaseResponse<boolean>>(`${AUTH_URL}/update`, request)
}

export function logoutCurrentUser(refreshToken: string) {
  return axios.post<BaseResponse<boolean>>(`${AUTH_URL}/revoke-token/${refreshToken}`)
}
