import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import { PageTitle } from '../../../_metronic/layout/core';
import { ScheduleSearchModel } from '../../common/models/_models';
import { useAuth } from '../../modules/auth';
import { getTeacherSchedule } from '../../modules/users/core/_requests';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

interface EventModel {
  id: string,
  name: string,
  allDay: boolean,
  start: Date,
  end: Date
  scheduleType: string
}

const DashboardPage = () => {
  const now = new Date();
  const auth = useAuth();
  const [events, setEvents] = useState<EventModel[]>([])
  const [view, setView] = useState('day')
  const [currentDay, setCurrentDay] = useState(new Date())
  const localizer = momentLocalizer(moment);

  const handleChangeDate = (newDate: Date, view: string) => {
    setView(view);
    setCurrentDay(newDate)
    let request = {
      startDate: newDate,
      endDate: newDate
    }

    if (view === 'week') {
      request = {
        startDate: moment(newDate).local().startOf('week').toDate(),
        endDate: moment(newDate).local().endOf('week').toDate()
      }
    }


    getSchedule(request);

    // let weekDates = [];

    // for (let i = 0; i < 7; i++)
    //   weekDates.push(moment(newDate).add(i, 'd'));


    // console.log(weekDates);
  }

  const getSchedule = async (request: ScheduleSearchModel) => {
    if (auth.currentUser) {
      let { data } = await getTeacherSchedule(auth.currentUser?.id, request)
      if (data.data) {
        let eventData = data.data.map(x => {
          return {
            start: new Date(x.startDate),
            end: x.scheduleType === "Test" ? moment(x.startDate).add(60, 'minutes').toDate() : new Date(x.endDate),
            name: x.name,
            id: x.parentId,
            allDay: false,
            scheduleType: x.scheduleType
          } as EventModel
        });
        setEvents(eventData)
      }
    }
  }


  useEffect(() => {
    getSchedule({
      startDate: moment().local().startOf('week').toDate(),
      endDate: moment().local().endOf('week').toDate()
    })
  }, [])

  const Event = (e: EventModel, viewType: string) => {
    document.title = "Tổng quan"

    return (
      <div className={clsx('w-100 h-100 border p-2 overflow-auto d-flex rounded', (e.scheduleType === 'Test' ? 'bg-warning text-dark' : 'bg-primary'))}>

        {
          viewType === 'day' && e.scheduleType === 'Test' && <div>
            <span className="material-symbols-rounded">
              edit_square
            </span>
          </div>

        }
        {
          viewType === 'day' && e.scheduleType === 'ClassRoom' && <div>
            <span className="material-symbols-rounded">
              school
            </span>
          </div>

        }

        <div className='ms-2 mt-1'>
          <div> {e.name}</div>
          <div> {moment(e.start).format('HH:mm')} - {moment(e.end).format('HH:mm')}</div>
        </div>

      </div>
    )
  }

  return (
    <>
      <div className='card mb-10 shadow-sm'>
        <div className='card-body p-0'>
          <div className="row">
            <div className='col-sm-8 p-10'>
              <h1 className='text-primary'>Chào mừng thầy/cô {auth.currentUser?.fullName} !</h1>
              <div>Nếu thầy/cô có bất kỳ thắc mắc nào, vui lòng <a href='https://forms.gle/oWPkYLAPuWi23Y6X8' target='_blank'>liên hệ với bộ phận hỗ trợ</a>. Chúc thầy/cô một ngày tốt lành.</div>
            </div>
            <div className='col-sm-4'>
              <div className='pt-5 pe-5 text-end'>
                <img width={250} src={toAbsoluteUrl('/media/teacher.png')} className='me-2' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <Calendar
            events={events}
            defaultDate={new Date()}
            localizer={localizer}
            views={{ day: true, week: true }}
            defaultView='week'
            onNavigate={(newDate, view) => handleChangeDate(newDate, view)}
            components={{
              event: (e) => Event(e.event, view)
            }}
            popup
            startAccessor={'start'}
            endAccessor={'end'}
            min={new Date(0, 0, 0, 7, 0, 0)}
            max={new Date(0, 0, 0, 22, 0, 0)}
            onView={(view) => handleChangeDate(currentDay, view)}
            messages={{
              next: ">",
              previous: "<",
              today: "Hôm nay",
              month: "Tháng",
              week: "Tuần",
              day: "Ngày"
            }}
          />
        </div>
      </div>
    </>
  )
}

const DashboardTeacherWrapper = () => {
  const { currentUser } = useAuth()

  return (
    <>
      <PageTitle description='Welcome back' breadcrumbs={[]}>
        {' Hello, ' + currentUser?.firstName}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardTeacherWrapper }
