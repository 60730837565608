import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';

import { StudentClassRoomModel } from '../../../../common/models/_models';
import { ReportScheduleMessageForm } from '../../core/_models';

type props = {
  handleClose: () => void,
  show: boolean,
  students: StudentClassRoomModel[],
  selectedStudent: ReportScheduleMessageForm[],
  handleSelected: (selected: string[]) => void
}

const SelectStudentModal: FC<props> = ({ show, handleClose, students, selectedStudent, handleSelected }) => {

  const [selected, setSelected] = useState<string[]>([])

  useEffect(() => {
    setSelected(selectedStudent.map(x => x.studentId))
  }, [show])

  const handleChange = (e: any) => {
    setSelected(e.selectedRows.map((x: any) => x.id))
  };

  const columns = [
    {
      name: 'Tên học sinh',
      selector: row => <><div className='fw-bold'>{row.firstName} {row.lastName}</div>
        <div className='small mt-1'>{row.phoneNumber}</div></>
    },
    {
      name: 'Email',
      selector: row => row.email
    },
    {
      name: 'Tên phụ huynh',
      selector: row => <div>
        <div className='fw-bold'>{row.parentName}</div>
        <div className='small mt-1'>{row.parentPhone}</div>
      </div>
    },
    {
      name: 'Gửi đến email',
      selector: row => row.parentEmail === '' ? <div className='d-flex align-items-center text-danger'><span className="material-symbols-rounded fs-3 me-2">
        warning
      </span> Không thể gửi báo cáo</div> : <div className='fw-bold'>{row.parentEmail}</div>
    },
  ] as TableColumn<StudentClassRoomModel>[];

  const defaultSelectedRows = useCallback((row: StudentClassRoomModel) => {
    return selectedStudent.some(s => s.studentId === row.id);
  }, [selectedStudent]);


  return (
    <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-items-center'>
          Thêm học sinh
          <a target='_blank' href='https://docs.google.com/document/d/12B_cQPNENX4LBysVqD4PUUIKrSt07zp1_fT25D7KZSY/edit#heading=h.aypa4qgtppd6' className='d-flex ps-2'>
            <span className="material-symbols-rounded fs-2">
              help
            </span>
          </a>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          show &&
          <DataTable
            columns={columns}
            data={students}
            selectableRows
            selectableRowSelected={defaultSelectedRows}
            onSelectedRowsChange={handleChange}
          />
        }

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={handleClose} >
          Đóng
        </Button>
        <Button variant="primary" onClick={() => handleSelected(selected)}>
          Lưu
        </Button>
      </Modal.Footer>

    </Modal>
  )
}

export default SelectStudentModal
