import axios from 'axios';

import { BaseResponse } from '../../../common/models/_models';
import { SearchReportSchedule, SearchReportScheduleResponse, WarningTeacherModel, UpsertWarningTeacherModel } from './_models';

const API_URL = process.env.REACT_APP_API_URL

export const REPORT_CONTROL_API = `${API_URL}/api/reportcontrol`

export function searchReportSchedules(request: SearchReportSchedule) {
  return axios.post<BaseResponse<SearchReportScheduleResponse[]>>(`${REPORT_CONTROL_API}/search`, request)
}

export function getListClassRoomWarningTeacher(classRoomId: string) {
  return axios.get<BaseResponse<WarningTeacherModel[]>>(`${REPORT_CONTROL_API}/class-rooms/${classRoomId}/warning/list`)
}

export function createClassRoomWarningTeacher(classRoomId: string, request: UpsertWarningTeacherModel ) {
  return axios.post<BaseResponse<boolean>>(`${REPORT_CONTROL_API}/class-rooms/${classRoomId}/warning`, request)
}

export function deleteClassRoomWarningTeacher(warningId: string) {
  return axios.delete<BaseResponse<boolean>>(`${REPORT_CONTROL_API}/warning/${warningId}`)
}
