import axios from 'axios'
import { BaseListResponse, BaseResponse, ScheduleModel, SearchModel, StudentClassRoomModel, UserModel } from '../../../common/models/_models'
import { ClassRoomAttendance, ClassRoomAttendanceRequest, ClassRoomDetailModel, ClassRoomModel, CreateReportSchedulerRequest, ReportScheduleMessage, ReportScheduleStatisticResponse, SearchReportScheduleResponse, SemesterModel, StudentAttendanceRequest, UpdateReportScheduleMessage, UpsertClassRoomModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const CLASS_ROOM_API = `${API_URL}/api/classrooms`
export const DOWNLOAD_REPORT_API = `${API_URL}/report/report-schedule/`


export function createClassRoom(request: UpsertClassRoomModel) {
  return axios.post<BaseResponse<string>>(CLASS_ROOM_API, request)
}

export function updateClassRoom(id: string, request: UpsertClassRoomModel) {
  return axios.put<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${id}`, request)
}

export function deleteClassRoom(id: string) {
  return axios.delete<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${id}`)
}

export function getClassRoomById(id: string) {
  return axios.get<BaseResponse<ClassRoomDetailModel>>(`${CLASS_ROOM_API}/${id}`)
}

export function searchClassRoom(request: SearchModel) {
  return axios.post<BaseListResponse<ClassRoomModel>>(`${CLASS_ROOM_API}/search`, request)
}

export function getStudentInClass(classRoomId: string) {
  return axios.get<BaseResponse<StudentClassRoomModel[]>>(`${CLASS_ROOM_API}/${classRoomId}/students`)
}

export function addStudentInClass(classRoomId: string, ids: string[]) {
  return axios.post<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${classRoomId}/students`, ids)
}

export function removeStudentInClass(classRoomId: string, id: string) {
  return axios.delete<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${classRoomId}/students/${id}`)
}

export function getClassRoomSchedules(classRoomId: string) {
  return axios.get<BaseResponse<ScheduleModel[]>>(`${CLASS_ROOM_API}/${classRoomId}/schedules`)
}


export function updateIsCompleted(classRoomId: string, value: boolean) {
  return axios.put<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${classRoomId}/iscompleted?value=${value}`)
}

export function getClassRoomAttendances(classRoomId: string) {
  return axios.get<BaseResponse<ClassRoomAttendance[]>>(`${CLASS_ROOM_API}/${classRoomId}/attendances`)
}

export function createClassRoomAttendances(classRoomId: string, request: ClassRoomAttendanceRequest) {
  return axios.post<BaseResponse<ClassRoomAttendance>>(`${CLASS_ROOM_API}/${classRoomId}/attendances`, request)
}

export function createStudentAttendances(classRoomId: string, attendanceId: string, request: StudentAttendanceRequest) {
  return axios.post<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${classRoomId}/attendances/${attendanceId}`, request)
}
export function deleteAttendances(classRoomId: string, attendanceId: string) {
  return axios.delete<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${classRoomId}/attendances/${attendanceId}`)
}

export function getReportSchedules(classRoomId: string, type: string) {
  return axios.get<BaseResponse<SearchReportScheduleResponse[]>>(`${CLASS_ROOM_API}/${classRoomId}/report-schedules?type=${type}`)
}

export function createReportSchedules(classRoomId: string, request: CreateReportSchedulerRequest) {
  return axios.post<BaseResponse<string>>(`${CLASS_ROOM_API}/${classRoomId}/report-schedules`, request)
}

export function updateReportSchedules(classRoomId: string, scheduleId: string, request: CreateReportSchedulerRequest) {
  return axios.put<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${classRoomId}/report-schedules/${scheduleId}`, request)
}
export function deleteReportSchedules(classRoomId: string, scheduleId: string) {
  return axios.delete<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${classRoomId}/report-schedules/${scheduleId}`)
}

export function getReportScheduleMessage(classRoomId: string, scheduleId: string, studentIds: string[]) {
  return axios.post<BaseResponse<ReportScheduleMessage[]>>(`${CLASS_ROOM_API}/${classRoomId}/report-schedules/${scheduleId}/messages/search`, studentIds)
}

export function updateReportScheduleMessage(classRoomId: string, scheduleId: string, request: UpdateReportScheduleMessage[]) {
  return axios.post<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${classRoomId}/report-schedules/${scheduleId}/messages`, request)
}

export function deleteReportScheduleMessage(classRoomId: string, scheduleId: string, request: string[]) {
  return axios.delete<BaseResponse<boolean>>(`${CLASS_ROOM_API}/${classRoomId}/report-schedules/${scheduleId}/messages`, {
    data: request
  })
}

export function getReportScheduleStatistic(classRoomId: string) {
  return axios.get<BaseResponse<ReportScheduleStatisticResponse>>(`${CLASS_ROOM_API}/${classRoomId}/report-schedules/statistics`)
}

export function getSemesters(classRoomId: string) {
  return axios.get<BaseResponse<SemesterModel[]>>(`${CLASS_ROOM_API}/${classRoomId}/semesters`)
}