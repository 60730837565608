/* eslint-disable jsx-a11y/anchor-is-valid */
import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';

import { EnumScheduleReportStatus, EnumScheduleReportType, EnumScheduleReportTypeToName } from '../../../common/enums';
import { StudentClassRoomModel } from '../../../common/models/_models';
import {
  ClassRoomModel,
  SearchReportSchedule,
  SearchReportScheduleResponse,
} from '../../../modules/class-rooms/core/_models';
import { searchReportSchedules } from '../../../modules/class-rooms/core/_reportControlRequests';
import { DOWNLOAD_REPORT_API, getStudentInClass, searchClassRoom } from '../../../modules/class-rooms/core/_requests';
import ReportStudentScheduleDetailModal from '../../../modules/class-rooms/modals/report-student-schedule/ReportStudentScheduleDetailModal';
import ListReportWarningModal from '../modals/ListReportWarningModal';


const ReportControlCard: React.FC = () => {
  const [reportSchedule, setReportSchedule] = useState<SearchReportScheduleResponse>();
  const [reportSchedules, setReportSchedules] = useState<SearchReportScheduleResponse[]>([]);
  const [showReportSchedule, setShowReportSchedule] = useState(false)
  const [showListWarning, setShowListWarning] = useState(false)
  const [classRoomId, setClassRoomId] = useState<string>("")
  const [classRooms, setClassRooms] = useState<ClassRoomModel[]>([]);
  const [students, setStudents] = useState<StudentClassRoomModel[]>([])

  useEffect(() => {
    getClassRooms();
  }, [])

  useEffect(() => {
    if (classRoomId !== '') {
      getStudent(classRoomId)
    }
  }, [classRoomId])

  const getStudent = async (classRoomId: string) => {
    const { data } = await getStudentInClass(classRoomId);
    if (data.data) {
      setStudents(data.data);
    }
  }

  const getReports = async (request: SearchReportSchedule) => {
    const { data } = await searchReportSchedules(request);
    if (data.data) {
      setReportSchedules(data.data);
    }
  }

  const getClassRooms = async () => {
    const { data } = await searchClassRoom({ pageIndex: 1, pageSize: 100, term: '' });
    if (data.data) {
      setClassRooms(data.data);
      getReports({ classRoomId: data.data[0].id })
      setClassRoomId(data.data[0].id)
    }
  }
  const columns = [
    {
      name: 'Tên báo cáo',
      selector: row => row.jobName,
      sortable: true
    },
    {
      name: 'Loại báo cáo',
      width: '150px',
      selector: row => EnumScheduleReportTypeToName[row.studentReportType ?? ""],
      sortable: true,
      sortFunction: (a, b) => { return a.studentReportType < b.studentReportType ? -1 : a.studentReportType > b.studentReportType ? 1 : 0; },
    },
    {
      name: 'Trạng thái',
      width: '150px',
      selector: row => renderScheduleStatus(row),
      sortable: true,
      sortFunction: (a, b) => { return a.status < b.status ? -1 : a.status > b.status ? 1 : 0; },
    },
    {
      name: 'Ngày gửi',
      selector: row => row.scheduleTime ? moment.unix(row.scheduleTime).format('DD/MM/YYYY HH:mm') : '',
      sortable: true
    },
    {
      name: 'Ngày tạo',
      selector: row => row.created ? moment.unix(row.created).format('DD/MM/YYYY HH:mm') : '',
      sortable: true
    },
    {
      name: 'Người tạo',
      selector: row => row.createdByUser,
      sortable: true
    },
    {
      name: 'Hành động',
      selector: row => renderActionButton(row),
      width: '100px'
    },

  ] as TableColumn<SearchReportScheduleResponse>[];

  const renderScheduleStatus = (row: SearchReportScheduleResponse) => {
    switch (row.status) {
      case EnumScheduleReportStatus.Draft:
        return <div className='badge badge-secondary'>Bản nháp</div>

      case EnumScheduleReportStatus.Queue:
        return <div className='badge badge-primary'>Đã lên lịch</div>

      case EnumScheduleReportStatus.Done:
        return <div className='badge badge-success'>Đã xong</div>

      case EnumScheduleReportStatus.Error:
        return <div className='badge badge-danger'>Lỗi</div>

      case EnumScheduleReportStatus.Canceled:
        return <div className='badge badge-secondary'>Đã hủy</div>
    }
  }

  const renderActionButton = (row: SearchReportScheduleResponse) => {
    return (
      <div>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a onClick={() => {
            setReportSchedule(row)
            setShowReportSchedule(true)
          }}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <span className="fs-3 material-symbols-rounded">
              view_comfy
            </span>
          </a>
          <a target='_blank' href={(DOWNLOAD_REPORT_API + row.id)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <span className="fs-3 material-symbols-rounded">
              download
            </span>
          </a>
        </div>
      </div>
    )
  }

  const reportType = [
    { value: EnumScheduleReportType.WeekReport, label: EnumScheduleReportTypeToName[EnumScheduleReportType.WeekReport] },
    { value: EnumScheduleReportType.MonthReport, label: EnumScheduleReportTypeToName[EnumScheduleReportType.MonthReport] },
    { value: EnumScheduleReportType.SemesterReport, label: EnumScheduleReportTypeToName[EnumScheduleReportType.SemesterReport] },
    { value: EnumScheduleReportType.WarningReport, label: EnumScheduleReportTypeToName[EnumScheduleReportType.WarningReport] },
  ]

  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Danh sách báo cáo</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Tổng cộng {reportSchedules.length} báo cáo</span>
          </h3>
        </div>

        <div className='card-body'>
          <div className='row mb-10'>
            {
              classRooms.length > 0 &&
              <Formik
                initialValues={{
                  classRoomId: classRooms[0].id

                } as SearchReportSchedule}
                onSubmit={(values) => {
                  getReports(values)
                  setClassRoomId(values.classRoomId)
                }}
              >
                {({ setFieldValue, submitForm, values }) => (
                  <>
                    <div className='col-sm-8' style={{ zIndex: 10 }}>
                      <div className='row'>
                        <div className='col-sm-3 mb-2'>
                          <Select
                            options={reportType}
                            defaultValue={{ value: (""), label: "Chọn loại báo cáo" }}
                            onChange={(e: any) => setFieldValue("type", e.value)} />
                        </div>
                        <div className='col-sm-3  mb-2'>

                          <Select
                            defaultValue={{ value: classRooms[0].id, label: classRooms[0].name }}
                            options={classRooms.map(x => { return { value: x.id, label: x.name } })}
                            onChange={(e: any) => setFieldValue("classRoomId", e.value)} />

                        </div>
                        <div className='col-sm-3 mb-2'>
                          <input type='search' className='form-control form-control-sm' placeholder='Tìm kiếm tên báo cáo...' onChange={(e: any) => setFieldValue('term', e.target.value)} style={{ height: 38 }} />
                        </div>

                        <div className='col-sm-3 mb-2'>
                          <button className='btn btn-sm btn-primary' onClick={submitForm}>
                            Tìm kiếm
                          </button>
                        </div>
                      </div>
                    </div>

                    {values.classRoomId &&
                      <div className='col-sm-4'>
                        <div className='d-flex justify-content-end'>
                          <button className='btn btn-sm btn-warning d-flex' onClick={() => setShowListWarning(true)}>
                            <span className="fs-3 material-symbols-rounded me-2">
                              warning
                            </span>
                            Gửi cảnh cáo
                          </button>
                        </div>
                      </div>
                    }
                  </>
                )}
              </Formik>
            }

          </div>
          <DataTable
            paginationComponentOptions={{
              rowsPerPageText: "Số hàng",
              rangeSeparatorText: "trong"
            }}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            columns={columns}
            data={reportSchedules}
            responsive
            noDataComponent={<span>Ấn vào <strong>tìm kiếm</strong> để bắt đầu xem danh sách</span>}
            highlightOnHover
          />

        </div>

      </div>

      <ReportStudentScheduleDetailModal
        show={showReportSchedule}
        handleClose={() => setShowReportSchedule(false)}
        students={students}
        classRoomId={classRoomId}
        reportSchedule={reportSchedule}
        type={reportSchedule?.studentReportType ?? ""} />

      <ListReportWarningModal
        handleClose={() => setShowListWarning(false)}
        show={showListWarning}
        classRoomId={classRoomId}
      />
    </>
  )
}

export default ReportControlCard
