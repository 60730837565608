export enum EnumScheduleReportType {
    WarningReport = 'WarningReport',
    WeekReport = 'WeekReport',
    MonthReport = 'MonthReport',
    SemesterReport = 'SemesterReport'
}


export const EnumScheduleReportTypeToString = {
    WarningReport: 'cảnh cáo',
    WeekReport: 'tuần',
    MonthReport: 'tháng',
    SemesterReport: 'học kỳ'
} as any
export const EnumScheduleReportTypeToName = {
    WarningReport: 'Cảnh cáo',
    WeekReport: 'Tuần',
    MonthReport: 'Tháng',
    SemesterReport: 'Học kỳ'
} as any

export const EnumScheduleReportTypeToUrl = {
    WarningReport: 'warning-schedules',
    WeekReport: 'week-schedules',
    MonthReport: 'month-schedules',
    SemesterReport: 'semester-schedules'
} as any