import { Field, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { UpsertWarningTeacherModel } from '../../../modules/class-rooms/core/_models';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    BlockQuote,
    Bold,
    ClassicEditor,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontSize,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Strikethrough,
    Table,
    TodoList,
    Undo,
} from 'ckeditor5';
import { UserModel } from '../../../common/models/_models';
import { createClassRoomWarningTeacher } from '../../../modules/class-rooms/core/_reportControlRequests';

type props = {
    handleClose: () => void,
    handleSuccess: () => void,
    show: boolean,
    classRoomId: string,
    teacher: UserModel
}

const SendReportWarningModal: FC<props> = ({ show, handleClose, classRoomId, teacher, handleSuccess }) => {

    const [initialValues, setInitialValues] = useState<UpsertWarningTeacherModel>({
        jobName: "",
        message: "",
        teacherId: teacher.id
    })
    useEffect(() => {
        setInitialValues({
            jobName: "",
            message: "",
            teacherId: teacher.id
        })
    }, [show])

    const editorConfiguration = {
        toolbar: {
            items: [
                'undo', 'redo', '|',
                'fontsize', 'fontColor', 'fontBackgroundColor',
                '|',
                'bold', 'italic', 'strikethrough',
                '|',
                'blockQuote',
                '|',
                'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
            ],
        },
        plugins: [
            Bold,
            Essentials,
            Heading,
            Indent,
            IndentBlock,
            Italic,
            Link,
            List,
            MediaEmbed,
            Paragraph,
            Table,
            Undo,
            BlockQuote,
            TodoList,
            Strikethrough,
            FontSize,
            FontColor,
            FontBackgroundColor
        ],
    };

    const handleSubmitForm = async (request: UpsertWarningTeacherModel) => {
        let data = await createClassRoomWarningTeacher(classRoomId, request)
        if (data.data) {
            handleSuccess()
        }
    }

    return (
        <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
            <Modal.Header>
                <Modal.Title className='d-flex align-items-center'>
                    Gửi nhắc nhở giáo viên {teacher.firstName} {teacher.lastName}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => handleSubmitForm(values)}
            >
                {({ setFieldValue, submitForm, values, errors, touched }) => (
                    <>

                        <Modal.Body>

                            <Form>
                                <Form.Group className="mb-5">
                                    <Form.Label>Tên báo cáo</Form.Label>
                                    <Field name="jobName" className="form-control" placeholder="Báo cáo tuần 1" />
                                    {errors.jobName && touched.jobName ? <div className='text-danger'>{errors.jobName}</div> : null}
                                </Form.Group>

                                <Form.Group className="mb-5" controlId="formBasicEmail">
                                    <Form.Label>Nội dung nhắc nhở</Form.Label>
                                </Form.Group>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={values.message}
                                    config={editorConfiguration}
                                    onChange={(ev, ed) => setFieldValue(`message`, ed.getData())}
                                />

                            </Form>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" size='sm' type="button" onClick={handleClose}>
                                Quay lại
                            </Button>
                            <Button variant="primary" size='sm' type="submit" className='w-100px' onClick={submitForm} >
                                Lưu
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>

        </Modal>
    )
}

export default SendReportWarningModal
