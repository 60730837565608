import { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ClassRoomDetailModel, SearchReportScheduleResponse, WarningTeacherModel } from '../../../modules/class-rooms/core/_models';
import { deleteClassRoomWarningTeacher, getListClassRoomWarningTeacher } from '../../../modules/class-rooms/core/_reportControlRequests';
import DataTable, { TableColumn } from 'react-data-table-component';
import { EnumScheduleReportStatus } from '../../../common/enums';
import moment from 'moment';
import SendReportWarningModal from './SendReportWarningModal';
import { getClassRoomById } from '../../../modules/class-rooms/core/_requests';
type props = {
    handleClose: () => void,
    show: boolean,
    classRoomId: string
}
const ExpandedComponent =
    (props: any) => {
        return <div className='mb-5 rounded bg-light-secondary  p-3 mx-2 comment' dangerouslySetInnerHTML={{ __html: props.data.message }}>

        </div>

    }
const ListReportWarningModal: FC<props> = ({ show, handleClose, classRoomId }) => {

    const [warningTeachers, setWarningTeachers] = useState<WarningTeacherModel[]>([])
    const [showAddModal, setShowAddModal] = useState(false)
    const [classRoom, setClassRoom] = useState<ClassRoomDetailModel>();

    useEffect(() => {
        if (show) {
            getTeacherWarning()
            getClassRoom()
        }
        else {
            setWarningTeachers([])
            setClassRoom(undefined)
        }
    }, [show])

    const getClassRoom = async () => {
        let { data } = await getClassRoomById(classRoomId);
        if (data.data) {
            setClassRoom(data.data);
        }
    }

    const getTeacherWarning = async () => {
        const { data } = await getListClassRoomWarningTeacher(classRoomId)
        if (data.data) {
            setWarningTeachers(data.data);
        }
    }

    const columns = [
        {
            name: 'Tên nhắc nhở',
            selector: row => row.jobName,
            sortable: true
        },

        {
            name: 'Trạng thái',
            width: '150px',
            selector: row => renderScheduleStatus(row),
            sortable: true,
            sortFunction: (a, b) => { return a.status < b.status ? -1 : a.status > b.status ? 1 : 0; },
        },
        {
            name: 'Ngày tạo',
            selector: row => row.created ? moment.unix(row.created).format('DD/MM/YYYY HH:mm') : '',
            sortable: true
        },
        {
            name: 'Hành động',
            selector: row => renderActionButton(row),
            width: '140px'
        },

    ] as TableColumn<WarningTeacherModel>[];

    const renderActionButton = (row: WarningTeacherModel) => {
        return (
            <div>
                <div className='d-flex justify-content-end flex-shrink-0'>
                    <button
                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1' onClick={() => handleDeleteWarning(row.id)}>
                        <span className="fs-3 material-symbols-rounded">
                            delete
                        </span>
                    </button>
                </div>
            </div>
        )
    }
    const renderScheduleStatus = (row: WarningTeacherModel) => {
        switch (row.status) {
            case EnumScheduleReportStatus.Draft:
                return <div className='badge badge-secondary'>Bản nháp</div>

            case EnumScheduleReportStatus.Queue:
                return <div className='badge badge-primary'>Đã lên lịch</div>

            case EnumScheduleReportStatus.Done:
                return <div className='badge badge-success'>Đã xong</div>

            case EnumScheduleReportStatus.Error:
                return <div className='badge badge-danger'>Lỗi</div>

            case EnumScheduleReportStatus.Canceled:
                return <div className='badge badge-secondary'>Đã hủy</div>
        }
    }

    const handleDeleteWarning = async (warningId: string) => {
        let data = await deleteClassRoomWarningTeacher(warningId)
        if (data.data) {
            getTeacherWarning()
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} fullscreen backdrop="static">
                <Modal.Header>
                    <Modal.Title className='d-flex align-items-center'>
                        Danh sách gửi nhắc nhở giáo viên lớp {classRoom?.name}
                    </Modal.Title>
                    <div className='d-flex'>
                        <Button variant="secondary" type="button" size='sm' className='w-100px me-3' onClick={handleClose} >
                            Quay lại
                        </Button>

                        <button className='btn btn-sm btn-primary d-flex' onClick={() => setShowAddModal(true)}>
                            <span className="fs-3 material-symbols-rounded me-2">
                                add
                            </span>
                            Gửi nhắc nhở
                        </button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div className='card'>
                        <div className='card-header border-0 pt-5'>
                            <div className='d-flex w-100 align-items-center'>
                                <DataTable
                                    paginationComponentOptions={{
                                        rowsPerPageText: "Số hàng",
                                        rangeSeparatorText: "trong"
                                    }}
                                    fixedHeader
                                    fixedHeaderScrollHeight="500px"
                                    columns={columns}
                                    data={warningTeachers}
                                    responsive
                                    noDataComponent="Không có nhắc nhở nào"
                                    expandableRows
                                    expandableRowsComponent={ExpandedComponent}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {
                classRoom?.teacher &&
                <SendReportWarningModal
                    handleClose={() => setShowAddModal(false)}
                    handleSuccess={() => { setShowAddModal(false); getTeacherWarning() }}
                    show={showAddModal}
                    classRoomId={classRoomId}
                    teacher={classRoom.teacher}
                />
            }
        </>
    )
}

export default ListReportWarningModal
